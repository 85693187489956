<template>
    <v-dialog v-model="getDepartmentServiceViewer.show" persistent width="75%">
        <v-card class="overflow-hidden">
            <v-card-title class="justify-center"> {{ getDepartmentServiceViewer.departmentName }}</v-card-title>
            <v-divider></v-divider>
            <v-card flat>
                <v-row no-gutters>
                    <v-col cols="8">
                        <v-img :src="getDepartmentServiceViewer.departmentMap" width="98%"></v-img>
                    </v-col>
                    <v-col cols="4">
                        <v-card flat>
                            <v-card-title class="d-flex align-center justify-center">Service Provided</v-card-title>
                            <v-divider></v-divider>
                            <v-card flat height="475" class="overflow-y-auto">
                                <v-card-text class="font-weight-bold pa-0">
                                    <v-treeview :items="items">
                                    </v-treeview>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
                <v-btn @click="$store.commit('closeDepartmentServiceViewer')" outlined color="primary" width="150">
                    Close </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "departmentServiceViewer",
    computed: {
        ...mapGetters(["getDepartmentServiceViewer"]),
        items() {
            return this.getDepartmentServiceViewer.services
        }
    }
}
</script>